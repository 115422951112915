
const artists = {
    "list":
        [
            {
                "id": "1",
                "name": "Don Palmiter",
                "bio": "Item artist.",
                "src": "static/images/artists/1.jpg"
            },
            {
                "id": "2",
                "name": "Tom Best",
                "bio": "Item artist.",
                "src": "static/images/artists/2.jpg"
            },
            {
                "id": "3",
                "name": "Anita Marra Rogers",
                "bio": "Item artist.",
                "src": "static/images/artists/3.jpg"
            },
            {
                "id": "4",
                "name": "Ken Crow",
                "bio": "Item artist",
                "src": "static/images/artists/4.jpg"
            },
            {
                "id": "5",
                "name": "Linda Sickman",
                "bio": "Item artist.",
                "src": "static/images/artists/5.jpg"
            },
            {
                "id": "6",
                "name": "Terri Steiger",
                "bio": "Item artist.",
                "src": "static/images/artists/6.jpg"
            },
            {
                "id": "7",
                "name": "Tammy Haddix",
                "bio": "Item artist.",
                "src": "static/images/artists/7.jpg"
            },
            {
                "id": "8",
                "name": "Robert Chad",
                "bio": "Item artist.",
                "src": "static/images/artists/8.jpg"
            },
            {
                "id": "9",
                "name": "Nina Aube",
                "bio": "Item artist.",
                "src": "static/images/artists/9.jpg"
            },
            {
                "id": "10",
                "name": "Edythe Kegrize",
                "bio": "Item artist.",
                "src": "static/images/artists/10.jpg"
            },
            {
                "id": "11",
                "name": "Bob Siedler",
                "bio": "Item artist.",
                "src": "static/images/artists/11.jpg"
            },
            {
                "id": "12",
                "name": "Joanne Eschrich",
                "bio": "Item artist.",
                "src": "static/images/artists/12.jpg"
            },
            {
                "id": "13",
                "name": "John(Collin) Francis",
                "bio": "Item artist.",
                "src": "static/images/artists/13.jpg"
            },
            {
                "id": "14",
                "name": "Ed Seale",
                "bio": "Item artist.",
                "src": "static/images/artists/14.jpg"
            },
            {
                "id": "15",
                "name": "LaDene Votruba",
                "bio": "Item artist.",
                "src": "static/images/artists/15.jpg"
            },
            {
                "id": "16",
                "name": "Patricia Andrews",
                "bio": "Item artist.",
                "src": "static/images/artists/16.jpg"
            },
            {
                "id": "17",
                "name": "Sue Tague",
                "bio": "Item artist.",
                "src": "static/images/artists/17.jpg"
            },
            {
                "id": "18",
                "name": "Tracy Larsen",
                "bio": "Item artist.",
                "src": "static/images/artists/18.jpg"
            },
            {
                "id": "19",
                "name": "Nello Williams",
                "bio": "Item artist.",
                "src": "static/images/artists/19.jpg"
            },
            {
                "id": "20",
                "name": "Orville Wilson",
                "bio": "Item artist.",
                "src": "static/images/artists/20.jpg"
            },
            {
                "id": "21",
                "name": "Sharon Visker",
                "bio": "Item artist.",
                "src": "static/images/artists/21.jpg"
            },
            {
                "id": "22",
                "name": "Duane Unruh",
                "bio": "Item artist.",
                "src": "static/images/artists/22.jpg"
            },
            {
                "id": "24",
                "name": "Katrina Bricker",
                "bio": "Item artist.",
                "src": "static/images/artists/24.jpg"
            },
            {
                "id": "25",
                "name": "Dill Rhodus",
                "bio": "Item artist.",
                "src": "static/images/artists/25.jpg"
            },
            {
                "id": "26",
                "name": "Julie Forsyth",
                "bio": "Item artist.",
                "src": "static/images/artists/26.jpg"
            },
            {
                "id": "27",
                "name": "Joyce Lyle",
                "bio": "Item artist.",
                "src": "static/images/artists/27.jpg"
            },
            {
                "id": "28",
                "name": "Kristina Gaughran",
                "bio": "Item artist.",
                "src": "static/images/artists/28.jpg"
            },
            {
                "id": "29",
                "name": "Kristina Kline",
                "bio": "Item artist.",
                "src": "static/images/artists/29.jpg"
            },
            {
                "id": "30",
                "name": "Sharon Pike",
                "bio": "Item artist.",
                "src": "static/images/artists/30.jpg"
            },
            {
                "id": "31",
                "name": "Ruth Donikowski",
                "bio": "Item artist.",
                "src": "static/images/artists/31.jpg"
            },
            {
                "id": "32",
                "name": "Robert Hurlburt",
                "bio": "Item artist.",
                "src": "static/images/artists/32.jpg"
            },
            {
                "id": "33",
                "name": "Jim Kemme",
                "bio": "Item artist.",
                "src": "static/images/artists/33.jpg"
            },
            {
                "id": "34",
                "name": "Jake Angell",
                "bio": "Item artist.",
                "src": "static/images/artists/34.jpg"
            },
            {
                "id": "35",
                "name": "Debra Nielsen",
                "bio": "Item artist.",
                "src": "static/images/artists/35.jpg"
            },
            {
                "id": "36",
                "name": "Donna Lee",
                "bio": "Item artist.",
                "src": "static/images/artists/36.jpg"
            },
            {
                "id": "37",
                "name": "Matt Johnson",
                "bio": "Item artist.",
                "src": "static/images/artists/37.jpg"
            },
            {
                "id": "38",
                "name": "Rodney Gentry",
                "bio": "Item artist.",
                "src": "static/images/artists/38.jpg"
            },
            {
                "id": "39",
                "name": "Julia Lee",
                "bio": "Item artist.",
                "src": "static/images/artists/39.jpg"
            },
            {
                "id": "40",
                "name": "Steve Goslin",
                "bio": "Item artist.",
                "src": "static/images/artists/40.jpg"
            },
            {
                "id": "41",
                "name": "Lynn Norton",
                "bio": "Item artist.",
                "src": "static/images/artists/41.jpg"
            },
            {
                "id": "42",
                "name": "Chris Webb",
                "bio": "Item artist.",
                "src": "static/images/artists/42.jpg"
            },
            {
                "id": "43",
                "name": "Kristina Kline - Gaughran",
                "bio": "Item artist.",
                "src": "static/images/artists/43.jpg"
            },
            {
                "id": "44",
                "name": "Mary Hamilton",
                "bio": "Item artist.",
                "src": "static/images/artists/44.jpg"
            },
            {
                "id": "45",
                "name": "Becky Hottel",
                "bio": "Item artist.",
                "src": "static/images/artists/45.jpg"
            },
            {
                "id": "46",
                "name": "Joyce Pattee",
                "bio": "Item artist.",
                "src": "static/images/artists/46.jpg"
            },
            {
                "id": "47",
                "name": "Peter Dutkin",
                "bio": "Item artist.",
                "src": "static/images/artists/47.jpg"
            },
            {
                "id": "48",
                "name": "Michele Pyda - Sevcik",
                "bio": "Item artist.",
                "src": "static/images/artists/48.jpg"
            },
            {
                "id": "49",
                "name": "Cullen Brown",
                "bio": "Item artist.",
                "src": "static/images/artists/49.jpg"
            },
            {
                "id": "50",
                "name": "Diana McGehee",
                "bio": "Item artist.",
                "src": "static/images/artists/50.jpg"
            },
            {
                "id": "51",
                "name": "Lane Feuer",
                "bio": "Item artist.",
                "src": "static/images/artists/51.jpg"
            },
            {
                "id": "52",
                "name": "Gregor Benedetti",
                "bio": "Item artist.",
                "src": "static/images/artists/52.jpg"
            },
            {
                "id": "53",
                "name": "Valerie Shanks",
                "bio": "Item artist.",
                "src": "static/images/artists/53.jpg"
            },
            {
                "id": "54",
                "name": "Fayrol Unverferth",
                "bio": "Item artist.",
                "src": "static/images/artists/54.jpg"
            },
            {
                "id": "55",
                "name": "Debra Murray",
                "bio": "Item artist.",
                "src": "static/images/artists/55.jpg"
            },
            {
                "id": "56",
                "name": "Joanne(Eschrich) Wright",
                "bio": "Item artist.",
                "src": "static/images/artists/56.jpg"
            },
            {
                "id": "57",
                "name": "Tim Bodendistel",
                "bio": "Item artist.",
                "src": "static/images/artists/57.jpg"
            },
            {
                "id": "58",
                "name": "Bob Kolar",
                "bio": "Item artist.",
                "src": "static/images/artists/58.jpg"
            },
            {
                "id": "59",
                "name": "Rich LaPierre",
                "bio": "Item artist.",
                "src": "static/images/artists/59.jpg"
            },
            {
                "id": "60",
                "name": "All artists",
                "bio": "Item artist.",
                "src": "static/images/artists/60.jpg"
            },
            {
                "id": "61",
                "name": "Ron Bishop",
                "bio": "Item artist.",
                "src": "static/images/artists/61.jpg"
            },
            {
                "id": "62",
                "name": "Rudy Garcia",
                "bio": "Item artist.",
                "src": "static/images/artists/62.jpg"
            },
            {
                "id": "63",
                "name": "Emma Leturgez - Smith",
                "bio": "Item artist.",
                "src": "static/images/artists/63.jpg"
            },
            {
                "id": "64",
                "name": "Mike Dirham",
                "bio": "Item artist.",
                "src": "static/images/artists/64.jpg"
            },
            {
                "id": "65",
                "name": "Alex Ho",
                "bio": "Item artist.",
                "src": "static/images/artists/65.jpg"
            },
            {
                "id": "66",
                "name": "Marjolein Bastin",
                "bio": "Item artist.",
                "src": "static/images/artists/66.jpg"
            },
            {
                "id": "67",
                "name": "Mike Adair",
                "bio": "Item artist.",
                "src": "static/images/artists/67.jpg"
            },
            {
                "id": "68",
                "name": "Thomas Blackshear",
                "bio": "Item artist.",
                "src": "static/images/artists/68.jpg"
            },
            {
                "id": "69",
                "name": "Cindy Pawlyshn",
                "bio": "Item artist.",
                "src": "static/images/artists/69.jpg"
            },
            {
                "id": "70",
                "name": "Jim Mahon",
                "bio": "Item artist.",
                "src": "static/images/artists/70.jpg"
            },
            {
                "id": "71",
                "name": "John(Collin)Francis",
                "bio": "Item artist.",
                "src": "static/images/artists/71.jpg"
            },
            {
                "id": "72",
                "name": "Nello Wiliams",
                "bio": "Item artist.",
                "src": "static/images/artists/72.jpg"
            },
            {
                "id": "73",
                "name": "Tim Bauer",
                "bio": "Item artist.",
                "src": "static/images/artists/73.jpg"
            },
            {
                "id": "74",
                "name": "Tobin Fraley",
                "bio": "Item artist.",
                "src": "static/images/artists/74.jpg"
            },
            {
                "id": "75",
                "name": "Mary Lou Faltico",
                "bio": "Item artist.",
                "src": "static/images/artists/75.jpg"
            },
            {
                "id": "76",
                "name": "Allie Jachimowicz",
                "bio": "Item artist.",
                "src": "static/images/artists/76.jpg"
            },
            {
                "id": "77",
                "name": "John Wagner",
                "bio": "Item artist.",
                "src": "static/images/artists/77.jpg"
            },
            {
                "id": "78",
                "name": "Mark Newman",
                "bio": "Item artist.",
                "src": "static/images/artists/78.jpg"
            },
            {
                "id": "79",
                "name": "Rob Stanphill",
                "bio": "Item artist.",
                "src": "static/images/artists/79.jpg"
            },
            {
                "id": "80",
                "name": "Thomas Kinkade",
                "bio": "Item artist.",
                "src": "static/images/artists/80.jpg"
            },
            {
                "id": "81",
                "name": "24 Artists",
                "bio": "Item artist.",
                "src": "static/images/artists/81.jpg"
            },
            {
                "id": "82",
                "name": "28 Artists",
                "bio": "Item artist.",
                "src": "static/images/artists/82.jpg"
            },
            {
                "id": "83",
                "name": "29 Artists",
                "bio": "Item artist.",
                "src": "static/images/artists/83.jpg"
            },
            {
                "id": "84",
                "name": "6 Artists",
                "bio": "Item artist.",
                "src": "static/images/artists/84.jpg"
            },
            {
                "id": "85",
                "name": "Andrew Brownsword",
                "bio": "Item artist.",
                "src": "static/images/artists/85.jpg"
            },
            {
                "id": "86",
                "name": "Betsey Clark",
                "bio": "Item artist.",
                "src": "static/images/artists/86.jpg"
            },
            {
                "id": "87",
                "name": "Chieko Kineri",
                "bio": "Item artist.",
                "src": "static/images/artists/87.jpg"
            },
            {
                "id": "88",
                "name": "Ken Sheldon",
                "bio": "Item artist.",
                "src": "static/images/artists/88.jpg"
            },
            {
                "id": "89",
                "name": "Kristine Kline - Gaughran",
                "bio": "Item artist.",
                "src": "static/images/artists/89.jpg"
            },
            {
                "id": "90",
                "name": "Martha Moldaver",
                "bio": "Item artist.",
                "src": "static/images/artists/90.jpg"
            },
            {
                "id": "91",
                "name": "Phu Dang",
                "bio": "Item artist.",
                "src": "static/images/artists/91.jpg"
            },
            {
                "id": "92",
                "name": "Robert Best",
                "bio": "Item artist.",
                "src": "static/images/artists/92.jpg"
            },
            {
                "id": "93",
                "name": "Robert Chad / Mary Hamilton",
                "bio": "Item artist.",
                "src": "static/images/artists/93.jpg"
            },
            {
                "id": "94",
                "name": "Robert Kolar",
                "bio": "Item artist.",
                "src": "static/images/artists/94.jpg"
            },
            {
                "id": "95",
                "name": "Sheyda Abvabi",
                "bio": "Item artist.",
                "src": "static/images/artists/95.jpg"
            },
            {
                "id": "96",
                "name": "Adam Chung",
                "bio": "Item artist.",
                "src": "static/images/artists/96.jpg"
            },
            {
                "id": "97",
                "name": "Tim Bishop",
                "bio": "Item artist.",
                "src": "static/images/artists/97.png"
            }
        ]
}
export default artists;

/*
Nina Aube
Edythe Kegrize
Bob Siedler
Joanne Eschrich
John(Collin) Francis
Ed Seale
LaDene Votruba
Patricia Andrews
Sue Tague
Tracy Larsen
Nello Williams
Orville Wilson
Sharon Visker
Duane Unruh
Tom Best
Katrina Bricker
Dill Rhodus
Julie Forsyth
Joyce Lyle
Kristina Gaughran
Kristina Kline
Sharon Pike
Ruth Donikowski
Robert Hurlburt
Jim Kemme
Jake Angell
Debra Nielsen
Donna Lee
Matt Johnson
Rodney Gentry
Julia Lee
Steve Goslin
Lynn Norton
Chris Webb
Kristina Kline - Gaughran
Mary Hamilton
Becky Hottel
Joyce Pattee
Peter Dutkin
Michele Pyda - Sevcik
Cullen Brown
Diana McGehee
Lane Feuer
Gregor Benedetti
Valerie Shanks
Fayrol Unverferth
Debra Murray
Joanne(Eschrich) Wright
Tim Bodendistel
Bob Kolar
Rich LaPierre
All artists
Ron Bishop
Rudy Garcia
Emma Leturgez - Smith
Mike Dirham
Alex Ho
Marjolein Bastin
Mike Adair
Thomas Blackshear
Cindy Pawlyshn
Jim Mahon
John(Collin)Francis
Nello Wiliams
Tim Bauer
Tobin Fraley
Mary Lou Faltico
Allie Jachimowicz
John Wagner
Mark Newman
Rob Stanphill
Thomas Kinkade
24 Artists
28 Artists
29 Artists
6 Artists
Andrew Brownsword
Betsey Clark
Chieko Kineri
Ken Sheldon
Kristine Kline - Gaughran
Martha Moldaver
Phu Dang
Robert Best
Robert Chad / Mary Hamilton
Robert Kolar
Sheyda Abvabi
Adam Chung
*/